import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "./common";
import { addQueryDictToUrl, offsetLimitSerializeQueryArgs } from "../utils";

export const providerApi = createApi({
  reducerPath: "providerApi",
  baseQuery: baseQuery(),
  tagTypes: ["Providers", "AllocatedProvider", "Provider", "ProviderAllocationInvite", "ProviderAllocationRequest"],
  endpoints: (builder) => ({
    // Allocation
    getOrAllocateProvider: builder.query<any, void>({
      query: () => `/api/providers-customers/customers/get-or-allocate`,
      providesTags: ["AllocatedProvider"],
    }),
    getAllocatedProvider: builder.query<any, void>({
      query: () => `/api/providers-customers/customers/get-allocated`,
      providesTags: ["AllocatedProvider"],
    }),
    replaceAllocatedProvider: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/providers-customers/customers/replace-provider`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AllocatedProvider"],
    }),
    setDeallocationReason: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/providers-customers/customers/deallocation-reason`,
        method: "POST",
        body: data,
      }),
    }),
    findNewprovider: builder.query<any, void>({
      query: () => `/api/providers-customers/customers/find-new-provider`,
    }),
    getCanChangeProvider: builder.query<any, void>({
      query: () => `/api/providers-customers/customers/can-change`,
      providesTags: ["AllocatedProvider"],
    }),
    // Search - single
    getProviderProfile: builder.query<any, string>({
      query: (provider_id) => `/api/providers-profiles/public/search/${provider_id}`,
      providesTags: ["Provider"],
    }),
    getProviderBilling: builder.query<any, string>({
      query: (provider_id) => `/api/providers/customers/billing/${provider_id}`,
      providesTags: ["Provider"],
    }),
    getProviderRatings: builder.query<ListResponse<any>, Record<string, any> | void>({
      query: (queryParams) => addQueryDictToUrl(`/api/providers-ratings/public/bookings`, queryParams),
      serializeQueryArgs: offsetLimitSerializeQueryArgs,
      providesTags: ["Providers"],
    }),
    getProviderAllocationInvite: builder.query<any, string>({
      query: (provider_id) => `/api/providers-customers/customers/allocation-proposals/${provider_id}`,
      providesTags: ["ProviderAllocationInvite"],
    }),
    acceptProviderAllocationInvite: builder.mutation<any, any>({
      query: ({ provider_id }) => ({
        url: `/api/providers-customers/customers/allocation-proposals/${provider_id}/accept`,
        method: "POST",
      }),
      invalidatesTags: ["ProviderAllocationInvite"],
    }),
    getHasExistingRequest: builder.query<any, void>({
      query: () => `/api/providers-customers/customers/allocation-requests/exists`,
      providesTags: ["ProviderAllocationRequest"],
    }),
    createAvailabilityRequest: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/providers-customers/customers/allocation-requests`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ProviderAllocationRequest"],
    }),
  }),
});
export const {
  useGetProviderBillingQuery,
  useGetAllocatedProviderQuery,
  useGetProviderRatingsQuery,
  useGetProviderProfileQuery,
  useGetOrAllocateProviderQuery,
  useGetCanChangeProviderQuery,
  useFindNewproviderQuery,
  useReplaceAllocatedProviderMutation,
  useSetDeallocationReasonMutation,
  useGetProviderAllocationInviteQuery,
  useAcceptProviderAllocationInviteMutation,
  useGetHasExistingRequestQuery,
  useCreateAvailabilityRequestMutation,
} = providerApi;
