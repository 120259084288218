import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./common";

export const emailsApi = createApi({
  reducerPath: "emailsApi",
  baseQuery: baseQuery(),
  tagTypes: [],
  endpoints: (builder) => ({
    emailClick: builder.mutation<any, any>({
      query: ({ tracker_id }) => ({
        url: `/api/emails-tracking/public/customer/${tracker_id}/click`,
        method: "POST",
      }),
    }),
  }),
});
export const { useEmailClickMutation } = emailsApi;
